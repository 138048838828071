<template>
  <tr>
    <td>{{ homeWork.name }}</td>
    <td>{{ homeWork.user_id + 100000 }}</td>
    <td>{{ homeWork.phone }}</td>
    <!--    <td>{{ this.homeWork.test.overall_id }}</td>-->
    <td class="d-flex justify-content-center" style="">
      <el-button style="margin: 0;" type="warning" v-if="!homeWork.test">Chưa làm bài tập</el-button>
      <el-button style="margin: 0;" type="primary" v-else-if="!homeWork.test.status_mark && homeWork.test.in_time">
        Chưa được chấm bài
      </el-button>
      <el-button class="bg-danger text-white" style="margin: 0;" v-else-if="!homeWork.test.status_mark && !homeWork.test.in_time">
        Nộp bài muộn
      </el-button>
      <el-button type="success" v-else-if="homeWork.test.status_mark">
        Đã chấm điểm
      </el-button>
    </td>
    <td class="text-center">
      <span v-if="!homeWork.test"></span>
      <span v-else-if="homeWork.test.has_handmade_mark_test">Chấm tự động & GV chấm</span>
      <span v-else>Chấm tự động</span>
    </td>
    <td>
      <span
          v-if="homeWork.test && homeWork.test.has_handmade_mark_test && !homeWork.test.homework_grader && homeWork.test.status_mark">
        {{ homeWork.test.updated_at | formatDateTimeVietnam }}
      </span>
    </td>
    <td class="text-center">
      <div @click="viewHomework" v-if="homeWork.test" title="Xem kết quả"
           class="btn btn-icon btn-xs btn-outline-success ml-2">
        <i class="far fa-eye"></i>
      </div>
      <div v-if="homeWork.test && !homeWork.test.status_mark"  class="btn btn-icon btn-xs btn-outline-info ml-2" title="Chấm BTVN"
           @click="actionMarkHomework">
        <i class="el-icon-edit"></i>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    homeWork: Object,
    learning_schedule_id: Number,
    lesssonNumber: Number
  },
  methods: {
    viewHomework() {
      const routeData = this.$router.resolve({
        name: "content-homework", params: { id: this.homeWork.classroom_id },
        query: {
          classroom_id: this.homeWork.classroom_id, learning_schedule_id: this.learning_schedule_id, student_id:
          this.homeWork.user_id, lesson: this.lesssonNumber, type: 'view-result', center_id: this.$route.query.center_id
        }
      });
      window.open(routeData.href, '_blank');
    },
    actionMarkHomework() {
      this.$router.push({
        name: "mark-homework", params: { id: this.homeWork.classroom_id },
        query: {
          classroom_id: this.homeWork.classroom_id, lesson: this.lesssonNumber,
          student_id: this.homeWork.user_id, overall_id: this.homeWork.test.overall_id, type: 'mark-homework',
          learning_schedule_id: this.$route.query.learning_schedule_id, center_id: this.$route.query.center_id
        }
      });
    },
    mounted() {
    }
  }

}
</script>


<style lang="scss" scoped>


.mx-datepicker {
  width: 100% !important;
}



el-dialog {
  margin-top: 3vh !important;
}
</style>